<template>
  <div class="share-setting">
    <pageTitle pageTitle="微信分享"></pageTitle>
    <div class="share-container">
      <div class="share-left">
        <vh-form
          :model="formShareInfo"
          ref="ruleShareForm"
          label-width="90px"
          :rules="ruleShareForm"
        >
          <vh-form-item label="标题" prop="title">
            <vh-input
              v-model="formShareInfo.title"
              class="textType"
              placeholder="请输入分享标题"
              autocomplete="off"
              :maxlength="30"
              v-clearEmoij
              show-word-limit
            ></vh-input>
            <p class="tip">提示：为了您的内容获得有效传播，建议文字不超过14个字符</p>
          </vh-form-item>
          <vh-form-item label="简介" prop="introduction">
            <vh-input
              v-model="formShareInfo.introduction"
              type="textarea"
              class="desc"
              :maxlength="45"
              placeholder="请输入分享简介"
              autocomplete="off"
              :autosize="{ minRows: 5 }"
              resize="none"
              show-word-limit
            ></vh-input>
            <p class="tip">提示：因手机型号不同简介会显示不完整，建议不超过32个字符</p>
          </vh-form-item>
          <vh-form-item label="图片" prop="img_url">
            <upload
              ref="share_uploader"
              :saveData="saveImageData"
              :heightImg="120"
              :domain_url="domain_url"
              :on-success="uploadAdvSuccess"
              :on-change="handleUploadChange"
              @delete="deleteImg"
              :auto-upload="false"
            >
              <div slot="tip">
                <p>建议尺寸：150*150px，小于1M</p>
                <p>支持jpg、png</p>
              </div>
            </upload>
            <!-- <div class="image_cropper">
              <div class="image_cropper_item">
                <span>模糊程度</span>
                <vh-slider
                  v-model="imageCropper.blurryDegree"
                  :max="10"
                  style="width: 300px"
                ></vh-slider>
                <span class="wid_block">{{ imageCropper.blurryDegree }}</span>
              </div>
              <div class="image_cropper_item">
                <span>背景亮度</span>
                <vh-slider
                  v-model="imageCropper.lightDegree"
                  :max="20"
                  style="width: 300px"
                ></vh-slider>
                <span class="wid_block">{{ imageCropper.lightDegree }}</span>
              </div>
            </div> -->
          </vh-form-item>
          <vh-form-item>
            <vh-button
              type="primary"
              round
              v-preventReClick
              @click="sureShareSetting('ruleShareForm')"
            >
              保存
            </vh-button>
          </vh-form-item>
        </vh-form>
      </div>
      <div class="share-right">
        <div class="share-title">
          <div class="share-img"><img :src="avatar" alt="" /></div>
          <div class="share-text">
            <div class="top">{{ formShareInfo.title }}</div>
            <div class="bottom">
              <p v-html="formShareInfo.introduction"></p>
              <span><img :style="{'object-fit': picModeStyle}" :src="formShareInfo.img_url || img" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4"
    ></begin-play>
    <!-- 裁剪组件 -->
    <cropper
      ref="shareCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="1 / 1"
    ></cropper>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import upload from '@/components/Upload/main';
  import beginPlay from '@/components/beginBtn';
  import { defaultAvatar } from '@/utils/ossImgConfig';
  import {
    sessionOrLocal,
    buildOssUploadProcessData,
    parseQueryString,
    buildUrlByMode
  } from '@/utils/utils';
  import cropper from '@/components/Cropper/index';
  export default {
    name: 'shareSet',
    components: {
      PageTitle,
      upload,
      beginPlay,
      cropper
    },
    data() {
      return {
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        // imageCropper: {
        //   blurryDegree: 0,
        //   lightDegree: 10,
        //   imageCropMode: 1,
        //   backgroundSize: {
        //     x: 0,
        //     y: 0,
        //     width: 0,
        //     height: 0
        //   }
        // },
        formShareInfo: {
          title: '',
          img_url: '',
          introduction: ''
        },
        ruleShareForm: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          img_url: [{ required: true, message: '请上传图片', trigger: 'blur' }],
          introduction: [{ required: true, message: '请输入简介', trigger: 'blur' }]
        },
        avatar: defaultAvatar,
        img: require('../../../common/images/share/img.jpg'),
        upload_process_data: '',
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    computed: {
      domain_url() {
        if (!this.formShareInfo.img_url) return '';
        return buildUrlByMode(this.formShareInfo.img_url, this.currentMode);
      },
      picModeStyle() {
        let modeStyle = {1: 'fill', 2: 'cover', 3: 'scale-down'};
        return modeStyle[this.currentMode];
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'sys/img_url',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    created() {
      this.getShareInfo();
    },
    methods: {
      getShareInfo() {
        this.$fetch('getShareSettingInfo', { webinar_id: this.$route.params.str })
          .then(res => {
            if (res.code === 200) {
              let title = res.data.title;
              title = title.length - 30 > 0 ? title.substring(0, 30) : title;
              this.formShareInfo.title = title;
              this.formShareInfo.introduction = this.repalceHtml(res.data.introduction);
        
              if (res.data.img_url) {
                this.handlerImageInfo(res.data.img_url);
              }
            }
          })
          .catch(res => {});
      },
      // 处理图片
      handlerImageInfo(url) {
        this.formShareInfo.img_url = url;
        let obj = parseQueryString(url);
        if(obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      // 保存设置项
      sureShareSetting(formName) {
        this.formShareInfo.webinar_id = this.$route.params.str;
        let params = {
          ...this.formShareInfo,
          img_url: this.$parseURL(this.domain_url).path
        };
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.$fetch('setShareSettingInfo', params)
              .then(res => {
                if (res.code === 200) {
                  this.$vhMessage({
                    message: `保存成功`,
                    showClose: true,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `保存失败`,
                  showClose: true,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          } else {
            return false;
          }
        });
      },
      repalceHtml(str) {
        let desc = null;
        desc = str.replace(/&nbsp;/g, '');
        desc = desc
          .replace(/<[^<>&]+>/g, '')
          .replace(/&(lt|gt|nbsp|amp|quot|middot);/gi, '')
          .replace(/(\r\n)|(\n)/g, '');
        desc = desc.length > 42 ? `${desc.trim().substring(0, 42)}...` : desc.trim();
        return desc;
      },
      deleteImg() {
        this.formShareInfo.img_url = '';
      },
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.share_uploader.upload();
        });
      },
      resetChooseFile() {
        this.$refs.share_uploader.resetChooseFile();
      },
      uploadAdvSuccess(res, file) {
        if (res.data) {
          this.formShareInfo.img_url = res.data.domain_url;
        }
      },
      handleUploadChange(file, fileList) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, ['png', 'jpeg'], 1, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.shareCropper.showModel(e.target.result);
            };
          });
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .share-setting {
    width: 100%;
    .share-container {
      display: flex;
      background: #fff;
      border-radius: 4px;
      padding: 48px 32px;
    }
    .share-left {
      width: 500px;
      .tip {
        color: #999;
        line-height: 30px;
        font-size: 14px;
      }

      // .image_cropper {
      //   width: 100%;
      //   margin-top: 10px;
      //   &_item {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     span {
      //       color: #595959;
      //     }
      //     .wid_block {
      //       display: inline-block;
      //       width: 16px;
      //     }
      //   }
      // }
    }
    .share {
      &-right {
        width: 420px;
        height: 690px;
        margin-top: -30px;
        margin-left: 30px;
        background-image: url('../../../common/images/share/share.png');
        background-size: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
      }
      &-title {
        position: absolute;
        top: 187px;
        left: 68px;
        display: flex;
      }
      &-img {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        // background: #999;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      &-text {
        width: 194px;
        // height: 90px;
        background: #fefffe;
        margin-left: 8px;
        padding: 6px 10px 8px 5px;
        .top {
          width: 100%;
          color: #1a1a1a;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 6px;
          overflow: hidden;
          max-height: 36px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          padding-left: 5px;
        }
        .bottom {
          display: flex;
          width: 100%;
          justify-content: space-between;
          p {
            color: #666;
            font-size: 12px;
            transform: scale(0.9);
            width: calc(100% - 42px);
            margin-right: 6px;
            padding: 0;
            text-align: left;
            line-height: 16px;
            overflow: hidden;
            max-height: 46px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          span {
            display: inline-block;
            width: 36px;
            height: 36px;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            margin-top: 3px;
            img {
              width: 100%;
              height: 100%;
              object-position: center top;
            }
          }
        }
      }
    }
  }
</style>
